
import React from 'react';

import { Link } from "gatsby"
import { graphql } from 'gatsby'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Layout from "../components/layout"
import Seo from "../components/seo"

import KeyVisual from '../components/key-visual';
import ArticlePreview from '../components/article-preview';
import FeaturedNews from '../components/featured-news';
import Pagenation from "../components/pagenation"

import * as styles from  '../components/news-list.module.scss'

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const NewsIndex = ({ props,data, pageContext}) => {
  const posts = data.allContentfulNewsPost.nodes;
  const tags = data.allContentfulTag.nodes;
  let pageNum = false
  if(pageContext.humanPageNumber === 1){
    pageNum = true
  }
  return (
    <Layout headerCheck={false} page="news">
      <Seo
        title="ニュース一覧 | becoz (ビコーズ)"
        description="DATAFLUCT (データフラクト) が運営する becoz (ビコーズ) のニュース一覧。becoz はデータサイエンスの力で環境価値を見える化し、カーボンニュートラルに向けた行動を促進する環境価値流通プラットフォームです。"
      />

      <KeyVisual
        titleEnglish={'News'}
        titleJapanese={'ニュース'}
      />

      <section className={`section ${styles.sectionNews}`}>
        <div className={styles.tagList}>
          <ul className={styles.tags}>
            <li className={styles.active}>
              <Link className="all" to="/news/">すべて</Link>
            </li>
            {tags.map(tag => {
              return (
                <li key={tag}>
                  <Link className={tag.contentful_id} to={"/news/tag/" + tag.contentful_id}>{tag.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        {pageNum && <FeaturedNews views={1}/>}
        <ArticlePreview className="article-wrap" posts={posts} />
        <Pagenation pageContext={pageContext} />
      </section>
    </Layout>
  )
}
export default NewsIndex

export const pageQuery = graphql`
query newsListQuery($skip: Int!, $limit: Int!) {
  allContentfulTag(sort: {fields: contentful_id}) {
    nodes {
      name
      contentful_id
    }
  }
  allContentfulNewsPost(
      filter: {node_locale: {eq: "ja"}, featured: {ne: true}}
      sort: { fields: [publishDate], order: DESC }
      skip: $skip
      limit: $limit
  ) {
    nodes {
      title
      slug
      publishDate(formatString: "YYYY.MM.DD")
      keyVisual {
        url
        gatsbyImageData
      }
      metadata {
        tags {
          contentful_id
          name
        }
      }
    }
  }
}

`