import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ArticlePreview from "./article-preview"

import * as styles from  './featured-news.module.scss'

const FeaturedNews = ({ views }) => {
  const FeaturedPosts = useStaticQuery(graphql`
    query {
      allContentfulNewsPost(filter: {
        node_locale: {
          eq: "ja"
        }
      }) {
        nodes {
          featured
          title
          slug
          id
          publishDate(formatString: "YYYY.MM.DD")
          metadata {
            tags {
              contentful_id
              name
            }
          }
          keyVisual {
            url
            gatsbyImageData
          }
        }
      }
    }
  `)


  const posts = FeaturedPosts.allContentfulNewsPost.nodes.filter(
    node => node.featured === true
  )
  if (!posts.length) {
    return null;
  }

  posts.length = views;

  return (
    <div className={styles.featuredNews}>
      <ArticlePreview className="article-wrap" posts={posts} featured={true}/>
    </div>
  )
}

export default FeaturedNews
